import { MAX_COUNT_FOR_API_REQUEST } from '@helpers/interfaces/api';
import { Plan, ageRanges, sumInsuredOptions, policyTerms, paymentFrequencies, insuranceTypes } from '@helpers/interfaces/plan';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Helper functions 
// const getKeyValue = <U extends keyof T, T extends object>(
//   key: U,
//   obj: T
// ) => obj[key]


// Interface of slice
interface PlanState{
  value:Plan
}



const initialState: PlanState = {
  value: {
    leadId:"",
    paymentFrequency:paymentFrequencies.Annual.value,
    sumInsured:sumInsuredOptions.lac1,
    insuranceType:insuranceTypes.Fam.value,
    ageGroup:ageRanges.ageGroup1,
    planStartDate: new Date(),
    planPeriod: new Date(),
    planPerHead:0,
    planTotal: 0, 
    numberOfEmployees: 7,
    policyTerm: policyTerms.Std.value,
  }
};

export const PlanSlice = createSlice({
  name: "Plan",
  initialState,
  reducers: {
    // setInsuranceType: (state: PlanState, action: PayloadAction<string>)=>{
    //   state.value.insuranceType = action.payload
    // },
    // setInitialAgeGroupBreakdown(state: PlanState, action: PayloadAction<number>) {
    //   state.value.ageGroupBreakdown.ageGroup1.number = 0.6*action.payload
    //   state.value.ageGroupBreakdown.ageGroup2.number = 0.2*action.payload
    //   state.value.ageGroupBreakdown.ageGroup3.number = 0.1*action.payload
    //   state.value.ageGroupBreakdown.ageGroup4.number = 0.1 * action.payload
    //   state.value.peopleCovered.numOfEmployees = action.payload
    // },
    // setMaternityCoverage(state: PlanState, action: PayloadAction<MaternityCoverage>) {
    //   state.value.maternityCoverage = action.payload
    // },
    // setNumOfEmployeesCovered(state: PlanState, action: PayloadAction<number>) {
    //   state.value.peopleCovered.numOfEmployees = action.payload
    // },
    // setNumOfSpouseCovered(state: PlanState, action: PayloadAction<number>) {
    //   state.value.peopleCovered.numOfSpouse = action.payload
    // },
    // setNumOfChildrenCovered(state: PlanState, action: PayloadAction<number>) {
    //   state.value.peopleCovered.numOfChild = action.payload
    // },
    // updateAgeGroupValue(state: PlanState, action: PayloadAction<{ ageGroupName: string, updatedValue: number }>) {
    //   // NOTE : For this reducer to work the "ageGroupName" must string-match one of the keys of interface "AgeGroupBreakdown" 
    //   const { ageGroupName, updatedValue } = action.payload

    //   const updaterFunc = (object:AgeGroupObj, updatedValue:number) => {
    //     state.value.peopleCovered.numOfEmployees += updatedValue - object.number
    //     object.number = updatedValue
    //   }
      
    //   switch (ageGroupName) {
    //     case AgeGroupNames.ageGroup1:
    //       updaterFunc(state.value.ageGroupBreakdown.ageGroup1, updatedValue) 
    //       break;
    //     case AgeGroupNames.ageGroup2:
    //       updaterFunc(state.value.ageGroupBreakdown.ageGroup2, updatedValue)
    //       break;
    //     case AgeGroupNames.ageGroup3:
    //       updaterFunc(state.value.ageGroupBreakdown.ageGroup3, updatedValue)
    //       break;
    //     case AgeGroupNames.ageGroup4:
    //       updaterFunc(state.value.ageGroupBreakdown.ageGroup4, updatedValue)
    //       break;
    //     case AgeGroupNames.ageGroup5:
    //       updaterFunc(state.value.ageGroupBreakdown.ageGroup5, updatedValue)
    //       break;
    //     default:
    //       return;
        
    //   }
    // }
    setSumInsured: (state: PlanState, action: PayloadAction<number>) => {
      state.value.sumInsured = action.payload
    },
    setNumberOfEmployees: (state: PlanState, action: PayloadAction<string>) => {
      state.value.numberOfEmployees = +action.payload < MAX_COUNT_FOR_API_REQUEST ? +action.payload : MAX_COUNT_FOR_API_REQUEST 
    },
    setAgeGroup:(state:PlanState, action:PayloadAction<number[]>)=>{
      state.value.ageGroup = action.payload
    },
    setPolicyTerm:(state:PlanState, action:PayloadAction<string>)=>{
      state.value.policyTerm = action.payload
    },
    setPaymentFrequency:(state:PlanState, action:PayloadAction<string>)=>{
      state.value.paymentFrequency = action.payload
    },
    setInsuranceType:(state:PlanState, action:PayloadAction<string>)=>{
      state.value.insuranceType = action.payload
    },
    setLeadId: (state: PlanState, action: PayloadAction<string>) => {
      state.value.leadId = action.payload;
    },
  }
});

export const {
  // setInsuranceType,
  // setInitialAgeGroupBreakdown,
  // setMaternityCoverage,
  // setNumOfChildrenCovered,
  // setNumOfSpouseCovered,
  // setNumOfEmployeesCovered,
  // updateAgeGroupValue
  setPaymentFrequency,
  setNumberOfEmployees,
  setSumInsured,
  setAgeGroup,
  setPolicyTerm,
  setInsuranceType,
  setLeadId
} = PlanSlice.actions;

export default PlanSlice.reducer;
