import usePageTracking from "@hooks/usePageTracking";
import { Suspense } from "react";
import { Route } from "react-router-dom";
import { IRoute } from "./config";

const RouteWithSubRoutes = (route: IRoute) => {
  usePageTracking()
  return (
    <Suspense fallback={route.fallback}>
      <Route
        path={route.path}
        render={(props) => {
          return <route.component {...props} />;
        }}
      />
    </Suspense>
  );
};

export default RouteWithSubRoutes;
