import backArrow from '@images/common/back-arrow.svg'
import { useHistory } from 'react-router'
import { BackButtonGo } from './index.styled'

const BackButton = () => {
    const history = useHistory()
    return (
        <BackButtonGo onClick = {()=> history.goBack()} >
            <img src={backArrow} alt="back option" />
        </BackButtonGo>
    )
}

export default BackButton