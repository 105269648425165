import { ITheme } from './types';

const theme: ITheme = {
    colors: {
        emerald10: '#E9F8F6',
        emerald20: '#1bb4a4',
        emerald30: '#066066',
        
        charcoal5: '#fcfcfc',
        charcoal10: '#f8f8fc',
        charcoal20: '#f8fbfa',
        charcoal30: '#f7f8fa',
        charcoal40: '#f1f3f5',
        charcoal50: '#dddddd',
        charcoal60: '#d1d1d1',
        charcoal70: '#d6d6d6',
        charcoal80: '#667985',
        charcoal90: '#696969',
        charcoal100: '#3e4a59',

        amethyst10: "#685F80",
        
        algae10:'#009cc3',
        
        aqua10: '#f5f9f9',
        aqua20:'#f8f9fa',
        aqua30:'#e5e5e5',
        
        blood10: '#fb4e4e',
        blood20: '#cb3c3a',
        blood30: '#D67358',
        
        chrome10:"#FFBD14",
        
        white: '#fff',
        
        beau10: '#D4E3EB',
        
        muted: '#A0AEC0',
        error: '#CB3C3A',
        primary: '#1BB4A4',
        secondary: '#F7F8FA',
        primaryBG: '#18A294',
        activeBG: '#F1F35',
        secondaryBG: 'rgba(212, 227, 235, 0.2)',
        errorBG: '#FAECEB',
        mutedBG: '#718096',
        primaryTransparent: 'rgba(27, 180, 164, 0.2)',
        mutedTransparent: 'rgba(160, 174, 192, 0.2)'
    },
    fontFamily: {
        inter: 'Inter',
        garamond: 'EB Garamond',
        roboto:'Roboto'
    },
    fontSizes: {
        landing: '1.35rem',
        title: '1rem',
        heading: '18px',
        body: '14px',
        sectionHeading: '10px',
        label: '12px'
    },
    borderRadius: {
        cardRadius:'0.7rem'
    },
    height: {
        navbarHeight:'10vh'
    },
    breakpoints: {
        breakpoint0: "1300px",
        breakpoint1: "1000px",
        breakpoint2: "580px",
        breakpoint3: "460px",
        breakpoint5: "410px",
        breakpoint6: "370px",
        breakpoint7: "350px",
        breakpoint4: "720px",
    },
    icons:{
        downArrow:"@images/common/dropdown-arrow.svg",
    }
};

export const colorLibrary = [
    '#066066',
    '#cb3c3a',
    '#7f2341',
    '#009cc3',
    '#81a269',
    '#d67358',
    '#685f80',
    '#b2ab67',
    '#7cb9b2',
    '#877d8e'
];

export default theme;
