import "bootstrap/scss/bootstrap.scss";
import "./assets/sass/styles.scss";
import { Provider } from "react-redux";
import { persistor, store } from "@redux/store";
import { PersistGate } from "redux-persist/integration/react";
import GlobalStyles from "./GlobalStyles";
import theme from "./theme";
import Router from "@router/Router";
import { routes } from "@router/config";
import { ThemeProvider } from "styled-components";

const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <PersistGate loading={null} persistor={persistor}>
          {/* <Router history={history} routes={routes} /> */}
          <Router routes={routes} />
        </PersistGate>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
