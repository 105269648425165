// API Data model -> https://docs.google.com/document/d/12FUbpySFPCXEVCHjyRRuhk9bcYJXtLz06Ay2F1kTe_M

import FamilyIcon from "@components/containers/InsuranceType/FamilyIcon"
import SelfIcon from "@components/containers/InsuranceType/SelfIcon"

//* Schemas related to coverage and benefits of plan
// export enum BuyingFor{
//     Employee = "Employee",
//     EmployeeAndSpouse = "Employee and Spouse",
//     EmployeeSpouseAndChild = "Employee, Spouse and Child"
// }

// export enum MaternityCoverage{
//     None = "None",
//     Normal35k = "Normal 35000 & C-Section 50000",
//     Normal50k = "Normal 50000 & C-Section 50000"
// }


//* Schemas related to age groups being insured
// export enum AgeGroupNames{
//     ageGroup1="ageGroup1",
//     ageGroup2="ageGroup2",
//     ageGroup3="ageGroup3",
//     ageGroup4="ageGroup4",
//     ageGroup5="ageGroup5",
// }

// export interface AgeGroupObj{
//     name: string,
//     ageRange: [number, number]
// }

// export interface AgeGroupBreakdown {
//     ageGroup1:AgeGroupObj,
//     ageGroup2:AgeGroupObj,
//     ageGroup3:AgeGroupObj,
//     ageGroup4:AgeGroupObj
//     ageGroup5:AgeGroupObj
// }

export interface Option<T> {
    value: T,
    description?: string,
    title?: string,
    default?:boolean,
    SvgIcon?:any
}

export type Options<T> = {
    [key:string]: Option<T>
}

export interface InsurerGuarantees{
    sumInsured: number,
    roomRent: number,
    icu: number,
    maternityCoverage: number,
    ambulance: number,
    loopHealthMembership:number
}

export enum PolicyTerms{
    Std="STANDARD",
    Special="SPECIAL"
}

export const policyTerms:Options<string> = {
    Std: {value:"STANDARD", title : "Standard terms", description:"With 2 years specific disease waiting period and 30 days Initial waiting period"},
    Special : {value:'SPECIAL',title : "Standard terms with Lower Waiting period",description:"With 1 year specific disease waiting period and 15 days Initial waiting period"},
}

export const insuranceTypes:Options<string> = {
    Fam : {value:'FAMILY',description:"Covers an Employee + Spouse and 2 Children",title:"Family Insurance", SvgIcon:FamilyIcon},
    Indiv : {value:"SELF", description:"Covers the Employee",title:"Individual Insurance",SvgIcon:SelfIcon},
}

export const paymentFrequencies:Options<string> = {
    Annual: {value:"ANNUAL",title:"Annual Payment"},
    Monthly : {value:'MONTHLY',title:"Monthly Payment"},
} 

export const sumInsuredOptions:any = {
    lac1 : 100000,
    lac2 : 200000,
    lac3 : 300000,
    lac5 : 500000,
} 

export const ageRanges:any = {
    ageGroup1: [18, 35],
    ageGroup2: [36, 45],
    ageGroup3: [46, 55],
    ageGroup4: [56, 60],
}


//? State interfaces
// Plan
export interface Plan{
    leadId:string,
    sumInsured: number,
    insuranceType: string,
    policyTerm: string,
    ageGroup:number[],
    numberOfEmployees:number,
    planStartDate: Date,
    planPeriod: Date,
    paymentFrequency:string,
    planPerHead: number,
    planTotal: number
}
