import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
 
import plan from "@features/planSlice";
import user from "@features/userSlice";
import appAction from '@features/appActionSlice'
import insurer from '@features/insurerSlice'

const persistConfig = {
  key: 'root',
  storage,
}

const rootReducer = combineReducers({
  plan,
  appAction,
  insurer
})

const persisted = persistReducer(persistConfig, rootReducer)

const configuredReducer = combineReducers({
  user,
  persisted
})
 
export const store = configureStore({
  reducer:configuredReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
})
});

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;