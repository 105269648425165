import { adityaBirlaInsurance, Insurer } from '@helpers/interfaces/insurer';
// import { SumInsuredEnum } from '@helpers/interfaces/plan';
import { createSlice } from "@reduxjs/toolkit";

// Interface of slice
interface InsurerState{
  insurers:Insurer[]
}

const initialState: InsurerState = {
    insurers : [
      adityaBirlaInsurance,
      // digitInsurance
    ]
}

export const InsurerSlice = createSlice({
  name: "Insurer",
  initialState,
  reducers: {
    // setISumInsured(state:InsurerState,action: PayloadAction<SumInsuredEnum>){
    //   state.persisted.insurers.map((insurer,i)=> insurer.coverage.sumInsured = action.payload)
    // },
    // setIMaternityCoverage(state: InsurerState, action: PayloadAction<MaternityCoverage>) {
    //     state.persisted.insurers.map((insurer,i)=> insurer.coverage.maternityCoverage = action.payload)
    // },
  },
});

// eslint-disable-next-line 
export const {
    // setISumInsured,
    // setIMaternityCoverage
} = InsurerSlice.actions;

export default InsurerSlice.reducer;
