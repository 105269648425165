import Navbar from "@components/atoms/Navbar";
import React, { useEffect } from "react";
import { BrowserRouter, Redirect, Switch } from "react-router-dom";
import { IRoute, RouteNames } from "./config";
import RouteWithSubRoutes from "./RouteWithSubRoutes";

interface IProps {
  routes: IRoute[];
}

const Router: React.FC<IProps> = ({ routes }) => {
  useEffect(() => {
    // eslint-disable-next-line
  }, [window.innerWidth]);
  return (
    <BrowserRouter >
        <Navbar />
        <div className="app-route-components">
          <Switch>
            {routes.map((route: IRoute) => (
              <RouteWithSubRoutes key={route.path} {...route} />
            ))}
            <Redirect to={RouteNames.HOME} />
          </Switch>
        </div>
    </BrowserRouter>
  );
};

export default Router;
