//! This file contains the definition "Data" which is an exact replication of the data model expected at the backend of this app
//! This file contains the definition "Response" which is an exact replication of the data model expected at the backend of this app

//? Old
// Based of the data model API is expecting -> https://docs.google.com/document/d/12FUbpySFPCXEVCHjyRRuhk9bcYJXtLz06Ay2F1kTe_M/edit
export interface AgeGroup{
    groupId: string,
    count: number,
    amount:string
}

// export interface CalculateAPIRequest {
//     userId: string;
//     insuranceCount: number;
//     insuranceType: string;
//     ageGroup: AgeGroup[],
//     addOns :[]
// }

export interface CalculateAPIResponse{
    quoteAmount:  number
}

export interface CalculateAPIRequest{
    numberOfEmployees: number
    sumInsured: number,
    ageGroup: { // [18 to 35] , [36 to 45], [46 to 55], [56 to 60] & [61 to 65]
        minAge: number, 
        maxAge: number 
    },
    policyTerm: string, // ENUM("SPECIAL", "STANDARD")
    paymentFrequency:string, // ENUM("MONTHLY", "ANNUAL")
    type: string, // ENUM("FAMILY", "SELF")
    leadId: string
}

export interface UpdateLeadAPIRequest{
    numberOfEmployees: number
    sumInsured: number,
    ageGroup: { // [18 to 35] , [36 to 45], [46 to 55], [56 to 60] & [61 to 65]
        minAge: number, 
        maxAge: number 
    },
    policyTerm: string, // ENUM("SPECIAL", "STANDARD")
    paymentFrequency:string, // ENUM("MONTHLY", "ANNUAL")
    type: string, // ENUM("FAMILY", "SELF")
}
// Since the API will run only when count of insured people is 5 or more, we can hardcode the value here
export const MIN_COUNT_FOR_API_REQUEST: number = 7;
export const MAX_COUNT_FOR_API_REQUEST: number = 500;

//? An example of request object hitting API
// {
//     "userId" : "123",
//     "insuranceCount": 50,
//     "insuranceType": "INS2",
//     "ageGroup": [
//         {
//             "groupId": "ageGroup1",
//             "count": 9,
//             "amount": "2_lac"
//         },
//         {
//             "groupId": "ageGroup1",
//             "count": 9,
//             "amount": "2_lac"
//         },
//         {
//             "groupId": "ageGroup2",
//             "count": 9,
//             "amount": "2_lac"
//         },
//         {
//             "groupId": "ageGroup3",
//             "count": 6,
//             "amount": "1_lac"
//         }
//     ],
//     "addOns": [
//         "addOn6"
//     ]
// }


//? New
// Create a lead

// URI: /lead
// Method: POST

// **Request Body for First time Buyer form**

// {
//     "name": "John Doe", //Regexp : /[0-9a-zA-Z ]{6,}/
//     "companyName": "ECorp LLC", //Regexp : /[0-9a-zA-Z ]{6,}/
//     "companyEmail": "john@ecorp.com", //Regexp : /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
//     "phoneNumber": "9999999997", //Regexp : /^[6-9][0-9]{9}$/
// }

// **Request Body for First time Buyer form**

// {
//     "name": "John Doe", //Regexp : /[0-9a-zA-Z ]{6,}/
//     "companyName": "ECorp LLC", //Regexp : /[0-9a-zA-Z ]{6,}/
//     "companyEmail": "john@ecorp.com", //Regexp : /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
//     "phoneNumber": "9999999997", //Regexp : /^[6-9][0-9]{9}$/,
// 		"renewal" : {
// 			"month": "JAN", // ENUM("JAN", "FEB", ..., "DEC")
// 			"year": 2021 // number > 2020
// 		}
// }

// **Success Response** 

// {
//     "leadId": "some-random-string"
// }

// **Error response** 

// Status Codes - `404` `500` `400`

// {
//     "status": "ERROR",
//     "message": "Bad Request"
// }

// ## Get a quote

// URI: /insuranceProvider/:insuranceProviderId/quote
// Method: POST

// **URL Params**

// InsuranceProviderId for Aditya Birla is `IP-uz4asphmcq`

// **Request Body**

// {
//     "numberOfEmployees": 10, // number
//     "sumInsured": 500000, // one of 500000, 300000, 200000, 100000
//     "ageGroup": { // [18 to 35] , [36 to 45], [46 to 55], [56 to 60] & [61 to 65]
//         "minAge": 36, 
//         "maxAge": 45 
//     },
//     "policyTerm": "SPECIAL", // ENUM("SPECIAL", "STANDARD")
//     "paymentFrequency": "ANNUAL", // ENUM("MONTHLY", "ANNUAL")
//     "type": "FAMILY", // ENUM("FAMILY", "SELF")
//     "leadId": "jFcRKv4Tfbb7iu_7ulHKN" // string
// }

// **Success Response** 

// {
//     "quoteAmount": 33600 // number
// }

// **Error response** 

// Status Codes - `404` `500` `400`

// {
//     "status": "ERROR",
//     "message": "Bad Request"
// }