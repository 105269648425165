import { UserProfile } from "@helpers/interfaces/user";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserState {
  value: UserProfile;
}

const initialState: UserState = {
  value: {
    companyName: "",
    emailAddress: "",
    contactNumber: "",
    employeeName: "",
    renewal: {
      month: "January",
      year: 2022,
    },
  },
};

export const UserSlice = createSlice({
  name: "User",
  initialState,
  reducers: {
    setEmployeeName: (state: UserState, action: PayloadAction<string>) => {
      state.value.employeeName = action.payload;
    },
    setCompanyName: (state: UserState, action: PayloadAction<string>) => {
      state.value.companyName = action.payload;
    },
    setEmailAddress: (state: UserState, action: PayloadAction<string>) => {
      state.value.emailAddress = action.payload;
    },
    setContactNumber: (state: UserState, action: PayloadAction<string>) => {
      state.value.contactNumber = action.payload;
    },
    setRenewalMonth: (state: UserState, action: PayloadAction<string>) => {
      console.log(action.payload, state.value.renewal.month);
      state.value.renewal.month = action.payload;
    },
    setRenewalYear: (state: UserState, action: PayloadAction<number>) => {
      state.value.renewal.year = action.payload;
    },
    
  },
});

export const {
  setEmployeeName,
  setCompanyName,
  setContactNumber,
  setEmailAddress,
  setRenewalMonth,
  setRenewalYear,
} = UserSlice.actions;

export default UserSlice.reducer;
