import {css} from 'styled-components';

interface FlexboxProps{
    readonly justifyContent:string
    readonly alignItems:string
    readonly flexDirection:string
}

export const Flexbox = ({  flexDirection, justifyContent, alignItems }:FlexboxProps) => css`
  justify-content: ${justifyContent};
  align-items: ${alignItems};
  flex-direction: ${flexDirection};
  display:flex;
`;