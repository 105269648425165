import { Link, useLocation} from "react-router-dom";
import logoDark from '@images/common/text-logo.svg'
import { Nav } from "./index.styled";
import BackButton from "../BackButton";
import { RouteNames } from "@router/config";

const Navbar = () => {
  const location = useLocation()
  return (
    <Nav>
        <Link to="/">
          {window.innerWidth < 720 && location.pathname !== RouteNames.HOME &&  <BackButton/>}
          <img
            src={logoDark}
            loading="lazy"
            alt="loophealth logo"
            width={150}
          />
        </Link>
    </Nav>
  );
};

export default Navbar;