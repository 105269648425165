import { Flexbox } from '@components/styles/mixins/Flexbox.styled';
import styled from 'styled-components';

export const BackButtonGo = styled.div`
    position: absolute;
	top: 40px;
    cursor:pointer;
	left: 40px;
	${Flexbox({justifyContent:'center', alignItems:"center", flexDirection: 'row'})};
	transition: 0.1s ease-in;
	&:hover {
		transform: scale(1.2);
	}
	@media all and (max-width: ${({ theme }) => theme.breakpoints.breakpoint1}) {
        left:20px
    }
	@media all and (max-width: ${({ theme }) => theme.breakpoints.breakpoint4}) {
		position:absolute;
		top:5vh;
		left:5vh;
		transform:translate(-50%,-50%);
		z-index:3;
	}
`;
