import adityaBirla from '@images/insurer_page/adityabirla.png'


export interface Insurer{
    id:number,
    coverage: InsurerCoverage,
    details:InsurerDetails
}

export interface InsurerDetails{
    logoImgSrc:string
    name: string,
    description: string,
    tandcLink: string,
}

export enum InsuranceType{
    Monthly="INS"
}

export interface InsurerCoverage{
    // sumInsured: SumInsuredEnum,
    roomRent:string,
    icu?: string|number,
    // maternityCoverage?: MaternityCoverage,
    ambulance?: string|number,
    coPay?: string,
    accidents?: string,
    dayCareTreatments?: string,
    namedAliments?: string,
    PED?: string,
    covidTreatments?:string
    initialWaitPeriod?: string,
    hospitalizations?: string,
    domiHospitalization?: string,
    preHospitalization?:string
    postHospitalization?:string
    OPD?: string,
    modernTreatment?:string,
    ayushCover?:string
}


// Insurance Providers
export const adityaBirlaInsurance: Insurer = {
    id:1,
    details: {
        logoImgSrc:adityaBirla,
        name: "Aditya Birla Insurance",
        description: "Life insurance plans for every goal in life",
        tandcLink: "https://www.adityabirlacapital.com/healthinsurance/term-conditions",
    },
    coverage: {
        // sumInsured: SumInsuredEnum.Lac1,
        roomRent: "1000",
        icu: 1000,
        ayushCover:"Upto ₹50,000 (IPD)",
        // maternityCoverage: MaternityCoverage.None,
        ambulance: 1000,
        modernTreatment: "Upto 50% of Sum Insured (IPD)",
        preHospitalization: "Covered upto 30 Days",
        postHospitalization: "Covered upto 60 Days",
        dayCareTreatments:"527 Procedures Covered",
        domiHospitalization:"Covered as defined in GHI Policy",
    }
}
