import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: ${({ theme }) => theme.fontFamily.inter};
    }
    .app-route-components {
        position: relative;
        width: 100%;
        height: 100%;
    }
    html {
        scroll-behavior: smooth;
    }
    body {
        background: #fff;
    }
`;
export default GlobalStyles;
