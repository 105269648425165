import { App, AppOptions, CalculationResponse } from "@helpers/interfaces/user";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AppActionState{
    value:App
}



const initialState: AppActionState = {
    value: {
        appAction:AppOptions.BUY,
        calculationResponse:{
          withGST: 0,
          withoutGST: 0,
          withGSTRounded:0,
        }
    }
}

export const AppActionSlice = createSlice({
  name: "AppAction",
  initialState,
  reducers: {
    setAppOption: (state: AppActionState, action: PayloadAction<AppOptions>) => {
      state.value.appAction = action.payload
    },
    setCalculationResponse: (state:AppActionState, action:PayloadAction<CalculationResponse>) => {
      state.value.calculationResponse = action.payload
    }
  },
});

export const { setAppOption, setCalculationResponse } = AppActionSlice.actions;

export default AppActionSlice.reducer;
