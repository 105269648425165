import { Flexbox } from "@components/styles/mixins/Flexbox.styled";
import styled from "styled-components";

export const Nav = styled.nav`
    background-color: ${({ theme }) => theme.colors.white};
    height: ${({ theme }) => theme.height.navbarHeight};
    ${Flexbox({
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "row",
    })}
    padding-left: 9.2rem;
    .navbar-brand{
        cursor:pointer;
    }
    @media all and (max-width: ${({ theme }) => theme.breakpoints.breakpoint4}) {
        ${Flexbox({ justifyContent: 'center', alignItems: 'center', flexDirection: 'row' })}
        padding-left:0;
    }
`;
