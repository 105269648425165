//* Interfaces help set the purpose of the user in the app

export enum AppOptions{
    BUY,
    RENEW
}

export interface CalculationResponse{
    withGST: number,
    withoutGST: number,
    withGSTRounded:number
}
// User
export interface UserProfile{
    // Currently, userId === emailAddress. Technically it should be a unique identifier
    employeeName:string,
    companyName: string,
    emailAddress: string,
    contactNumber: string,
    renewal:{
        month:string,
        year:number 
    }
}

// UserAction
export type App = {
    appAction: AppOptions,
    calculationResponse: CalculationResponse
}
