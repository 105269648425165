import SplashScreen from "@components/atoms/SplashScreen";
// import { MIN_COUNT_FOR_API_REQUEST } from '@helpers/interfaces/api';
import { ComponentType, lazy, LazyExoticComponent, ReactNode } from "react";

export interface IRoute {
  // Path, like in basic prop
  path: string;
  // Exact, like in basic prop
  exact: boolean;
  // Preloader for lazy loading
  fallback: NonNullable<ReactNode> | null;
  // Lazy Loaded component
  component: LazyExoticComponent<ComponentType<any>>;
  // Redirect path
  redirect?: string | null;
}

export enum RouteNames {
  HOME = "/",
  ORDER = "/order",
  INSURER = "/insurer",
  PLAN = "/plan",
  BUSINESS = "/details",
  SUMMARY = "/summary",
  PAYMENT_SUCCESS = "/payment-success",
  THANK_YOU = "/thank-you",
}

export const routes: IRoute[] = [
  {
    path: RouteNames.HOME,
    component: lazy(() => import("@components/pages/LandingPage")),
    exact: true,
    fallback: <SplashScreen />,
  },
  {
    path: RouteNames.PLAN,
    component: lazy(() => import("@components/pages/PlanPage")),
    exact: true,
    redirect: null,
    fallback: <SplashScreen />,
  },
  {
    path: RouteNames.SUMMARY,
    component: lazy(() => import("@components/pages/OrderSummaryPage")),
    exact: true,
    fallback: <SplashScreen />,
  },
  {
    path: RouteNames.INSURER,
    component: lazy(() => import("@components/pages/InsurerPage/InsurerPage")),
    exact: true,
    fallback: <SplashScreen />,
  },
  {
    path: RouteNames.THANK_YOU,
    component: lazy(() => import("@components/pages/ThankYouPage")),
    exact: true,
    fallback: <SplashScreen />,
  },
];
