import React from 'react'
import logoDark from '@images/common/text-logo.svg'


const SplashScreen = () => {
    return (
        <div className="splash-screen" >
            <img
                src={logoDark}
                loading="lazy"
                alt="loophealth logo"
              />
            Loading...
        </div>
    )
}

export default SplashScreen
